<script>
import { useQrConnect } from '/~/extensions/qr-connect/composables'
import { formatDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUser } from '/~/composables/user'

export default {
  name: 'qr-reservation-item',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    claim: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { user } = useUser()
    const { cancelClaim } = useQrConnect()

    return {
      user,
      formatDate,
      cancelClaim,
    }
  },
  data() {
    return {
      showDialog: false,
      removing: false,
      deleted: false,
    }
  },
  computed: {
    businessAsset() {
      return this.claim.businessAsset
    },
    metadata() {
      return this.businessAsset.metadata || []
    },
    building() {
      return this.metadata.find((i) => i.name === 'Building') || {}
    },
    floor() {
      return this.metadata.find((i) => i.name === 'Floor') || {}
    },
  },
  methods: {
    deleteReservation() {
      this.removing = true

      this.cancelClaim({
        claim: this.claim.id,
        userExternalId: this.user.externalId,
      }).then(() => {
        this.$emit('delete')
        this.showDialog = false
        this.deleted = true
      })
    },
  },
}
</script>

<template>
  <div
    class="relative mb-6 rounded-md bg-white px-[30px] py-6 font-bold leading-tight transition sm:mb-0"
    :class="[deleted && 'opacity-0']"
  >
    <div class="flex justify-between">
      <div class="flex flex-col text-eonx-neutral-800">
        <div class="mb-2.5 text-xl">
          {{ building.data }}
        </div>
        <div class="flex flex-col text-xs sm:flex-row sm:text-base">
          <div class="mb-2.5 mr-[15px] flex flex-col sm:mb-0">
            <div class="opacity-50 sm:text-xs">Level</div>
            <div class="sm:text-sm">
              {{ floor.data }}
            </div>
          </div>
          <div class="flex flex-col">
            <div class="opacity-50 sm:text-xs">Workspace</div>
            <div class="sm:text-sm">
              {{ businessAsset.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10 flex items-center sm:mt-0">
        <div class="flex justify-between pr-2.5">
          <div
            class="flex flex-col rounded-md bg-primary p-2.5 text-center text-white"
          >
            <div class="text-xs font-normal uppercase">
              {{ formatDate('monthshort', claim.startsAt) }}
            </div>
            <div class="text-xl font-bold">
              {{ formatDate('day', claim.startsAt) }}
            </div>
          </div>
        </div>
        <div class="flex justify-between">
          <div
            class="flex flex-col rounded-md bg-primary p-2.5 text-center text-white"
          >
            <div class="text-xs font-normal uppercase">
              {{ formatDate('monthshort', claim.endsAt) }}
            </div>
            <div class="text-xl font-bold">
              {{ formatDate('day', claim.endsAt) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete section -->
    <!-- Resize background -->
    <div
      class="absolute bg-white p-2.5 transition-all duration-300"
      :class="[
        showDialog
          ? 'top-0 right-0 mt-0 mr-0 h-full w-full rounded-md opacity-100'
          : 'top-0 right-0 -mt-2.5 -mr-2.5 rounded-3xl opacity-0',
      ]"
    />

    <!-- Fade icon -->
    <transition name="fade">
      <div
        v-if="!showDialog"
        class="absolute top-0 right-0 -mt-2.5 -mr-2.5 flex transform cursor-pointer items-center justify-center rounded-full bg-fg-error p-2.5 text-white transition duration-300 hover:scale-110 hover:opacity-75"
        @click="showDialog = true"
      >
        <base-icon class="flex text-white" svg="qr/bin" :size="20" />
      </div>
    </transition>

    <transition name="slide-y">
      <div
        v-if="showDialog"
        class="absolute inset-0 flex h-full flex-col items-center justify-center"
      >
        <div class="pb-2.5 text-white">Are you sure you want to delete?</div>
        <div class="flex w-full px-[15px]">
          <base-button
            class="mx-[5px] grow rounded-md py-2.5"
            color="error"
            :disabled="removing"
            @click="showDialog = false"
          >
            No
          </base-button>
          <base-button
            class="mx-[5px] grow rounded-md py-2.5"
            color="error"
            :loading="removing"
            :disabled="removing"
            @click="deleteReservation"
          >
            Yes
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>
